.full-gallery-container{
  background: linear-gradient(to right, #f5f5f5, #e8e8e8);
}

.gallery-container-1 {
  padding-top: 4rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 140px;
  background: linear-gradient(to right, #f5f5f5, #e8e8e8);
  display: flex;
  justify-content: center;
}

.cards-full-wrapper-1{
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}


.gallery-container-2 {
  padding-top: 4rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 70px;
  background: linear-gradient(to right, #f5f5f5, #e8e8e8);
  display: flex;
  justify-content: center;
}

.cards-full-wrapper-2{
  max-width: 1400px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.gallery-container-3 {
  padding-top: 4rem;
  padding-left: 4rem;
  padding-right: 4rem;
  padding-bottom: 100px;
  background: linear-gradient(to right, #f5f5f5, #e8e8e8);
  display: flex;
  justify-content: center;
}

.mobile-gallery-1{
  display: none;
}

.mobile-gallery-2{
  display: none;
}

.mobile-gallery-3{
  display: none;
}

.gallery-1 {
  flex: 3; /* Adjust as necessary for the gallery size */
  width: 100%; /* Gallery takes full width */
}

.gallery-text-1 {
  flex: 2; /* Adjust the size of the text area */
  padding-left: 70px; /* Space between gallery and text */
  margin-top: 70px;
}


.gallery-2 {
  flex: 3; /* Adjust as necessary for the gallery size */
  width: 100%; /* Gallery takes full width */
}

.gallery-text-2 {
  flex: 2; /* Adjust the size of the text area */
  margin-top: 120px;
  margin-right: 120px;
}

.cards-title {
  font-family: 'Shadows Into Light Two', cursive;
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 20px;
    text-align: center;
    color: #b0843e;
}

.cards-subtext{
  font-size: 1.5rem;
  margin-bottom: 40px;
  text-align: center;
  color: #555;
  font-family: 'Lato', sans-serif;
}

.book-button-container{
  display: flex;
  justify-content: center;
}

.booking-button {
  background-image: linear-gradient(145deg, #009ffd, #2a2a72); /* Gradient background */
  color: #fff;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  cursor: pointer;
  border-radius: 8px; /* Slightly increased border-radius for a modern look */
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease; /* Smooth transitions for multiple properties */
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16); /* Subtle shadow for depth */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1); /* Text shadow for better readability */
  outline: none; /* Remove the outline to clean up focus state, consider replacing with a custom focus style for accessibility */
  letter-spacing: 0.05em; /* Slightly increase letter spacing for a modern feel */
  text-decoration: none;
  font-family: 'Lato', sans-serif;
}

.booking-button:hover, .booking-button:focus {
  transform: translateY(-2px); /* Slight lift effect on hover/focus */
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover for a "lifting" effect */
}

.booking-button:active {
  transform: translateY(1px); /* Button presses down on click */
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Shadow retracts as if the button is pressed */
}


.row {
  display: flex;
  justify-content: stretch; /* Stretch row items to fill the container */
  margin-bottom: 10px;
}

.row img {
  flex-grow: 1; /* Allow images to grow and fill the container */
  width: auto; /* Adjust width automatically */
  height: auto; /* Adjust height automatically */
  object-fit: cover; /* Maintain aspect ratio */
  margin: 0 5px; /* Optional: for spacing between images */
}


/* Style for the first row of images */
.row:first-child img {
  margin: 0 5px;
  width: calc(31.25% - 20px); /* Increased from 25% to 31.25% */
  height: auto;
  object-fit: cover;
  border-radius: 15px;
}

/* Style for the second row of images */
.row:last-child img {
  margin: 0 5px;
  width: calc(20.833% - 20px); /* Increased from 16.666% to 20.833% */
  height: auto;
  object-fit: cover;
  border-radius: 15px;
}

@media (max-width: 1134px) {

  .cards-title {
    font-size: 2.5rem;
}

.cards-subtext{
  font-size: 1.25rem;
}

.gallery-text-1 {
  padding-left: 20px; /* Space between gallery and text */
  margin-top: 0px;
  
}

.gallery-text-2 {
  margin-top: 0px;
  margin-right: 20px;
}

}

@media screen and (max-width: 960px) {
  .gallery-1 {
    flex: 4; /* Adjust as necessary for the gallery size */
  }
  .gallery-2 {
    flex: 4; /* Adjust as necessary for the gallery size */
  }
  .cards-title {
      font-size: 1.5rem;
  }
  .cards-subtext{
    font-size: 1rem;
  }

  .booking-button {
    padding: 10px 20px;
    font-size: 1rem;
  }
}

@media screen and (max-width: 768px) {

  .cards-full-wrapper-1{
    flex-direction: column;
  }

  .cards-full-wrapper-2{
    flex-direction: column;
  }

  .mobile-gallery-1{
    display: flex;
    margin-bottom: 60px;
    margin-top: 60px;
  }

  .mobile-gallery-2{
    display: flex;
    margin-bottom: 60px;
    margin-top: 60px;
  }

  .mobile-gallery-3{
    display: flex;
    margin-bottom: 60px;
    margin-top: 60px;
  }
  
  
  .gallery-1 {
    display: none;
    flex: 3; /* Adjust as necessary for the gallery size */
    width: 100%; /* Gallery takes full width */
  }

  .gallery-2 {
    display: none;
  }

  .gallery-container-1 {
    padding: 0rem;
    padding-left: 10px;
    padding-right: 10px;
  }

  .gallery-container-2 {
    padding: 0rem;
    padding-left: 10px;
    padding-right: 10px;
  }

  .gallery-container-3 {
    padding: 0rem;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 60px;
  }

  .gallery-text-1 {
    padding-left: 0px;
  }
  .gallery-text-2 {
    margin-right: 0px;
  }
  .cards-title {
    font-size: 2rem;
  }
  .cards-subtext{
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    line-height: 35px;
  }
  .booking-button {
    padding: 15px 30px;
    font-size: 1rem;
  }
}

