.testimonials-section {
    padding: 70px;
    background: linear-gradient(to right, #f5f5f5, #e8e8e8);
    text-align: center;
  }
  
  .testimonials-carousel {
    display: flex;
    overflow-x: auto;
    gap: 30px;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .testimonial {
    min-width: 300px;
    padding: 20px;
  }

  .testimonial-title {
    text-align: left;
    font-family: 'Shadows Into Light Two', cursive;
    font-weight: bold;
    font-size: 3rem;
    color: #b0843e;
    margin-bottom: 30px;
    padding-left: 20px;
  }
  
  .quote {
    font-style: italic;
    color: #555;
    font-size: 1.2rem;
    font-family: 'Merriweather', serif; /* Elegant serif font */
    line-height: 1.8; /* Increased line spacing for better readability */
  }
  
  .author {
    margin-top: 20px;
    font-weight: bold;
    color: #333;
  }
  
  @media screen and (max-width: 768px) {
    .testimonial-title {
      font-size: 2.5rem;
      text-align: center;
    }
    .testimonial {
      min-width: 200px;
  }

    .quote {
      font-size: 0.8rem;
      line-height: 1.8; /* Increased line spacing for better readability */
    }
  }
  