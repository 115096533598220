
.gallery__container {
  display: grid;
  width: 100%; /* Ensure the gallery uses the full width */
  grid-template-columns: repeat(auto-fit, minmax(500px, 1fr)); /* Larger minimum size for bigger images */
  gap: 20px;
  background: linear-gradient(to right, #f5f5f5, #e8e8e8);
  justify-content: space-between;
    align-items: flex-start;
    padding: 3%;
}

.gallery-item {
  display: flex;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  padding-top: 75%;
}

.gallery-item img {
  position: absolute; /* Adjusted to position absolutely within the .gallery-item */
  top: 0;
  left: 0;
  width: 100%; /* Full width of the container */
  height: 100%; /* Full height of the container, now defined by padding-top */
  object-fit: cover; /* Cover the space without distortion */
  display: block; /* Block level */
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}




.enlarged-img {
  width: 100vw; /* Adjust based on your preference for "most of the screen" */
  height: 75vw; /* 4:3 aspect ratio */
  max-width: 1000px; /* Example max size */
  max-height: 750px; /* Example max size, maintaining 4:3 aspect ratio */
  object-fit: contain;
}

  
  @media (max-width: 1147px) {
    .gallery__container {
      /* Example: Set to 3 columns; adjust minmax() as needed for your design */
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 947px) {
    .gallery__container {
      grid-template-columns: repeat(2, 1fr); /* Creates a two-column layout */
      gap: 10px;
    }
  }
  
@media (max-width: 768px) {
  .gallery__container {
    grid-template-columns: repeat(2, 1fr); /* Creates a two-column layout */
  }
  .enlarged-img {
    width: 70vw;
    height: 52.5vw; /* Maintain 4:3 aspect ratio */
  }

}


@media (max-width: 480px) {
  .gallery__container {
    grid-template-columns: 1fr; /* Single column layout on very small screens */
  }
  .enlarged-img {
    width: 90vw;
    height: 67.5vw; /* Maintain 4:3 aspect ratio */
  }
}