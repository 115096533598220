.hero-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.hero-image {
    position: absolute;
    left: 100%; 
    width: 100%;
    height: 100%; 
    object-fit: cover;
    transition: left 1s ease-in-out;
    z-index: -1;
    object-position: center;
}


.no-transition {
    transition: none !important;
}


.hero-image.active {
    left: 0; /* Active image sits in the view */
}

.hero-image.slide-out {
    left: -100%; /* Slide out the previous image */
}


.hero-container {
    height: 60vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    object-fit: contain;
}

.mobile-title-container {
    display: none;
    background: linear-gradient(to right, #f5f5f5, #e8e8e8);
}

.mobile_title_container {
    display: none;
}


.hero-container > h1 {
    color: #fff;
    justify-content: center;
    font-size: 100px;
    margin-top: 100px;
}

.hero-container > p {
    margin-top: 8px;
    color: #fff;
    font-size: 32px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.hero-btns {
    margin: 32px;
}

.hero-btns .btn {
    margin-top: 25px;
}

.fa-play-circle {
    margin-left: 4px;
}

@media screen and (max-width: 960px) {
    .hero-container > h1 {
        font-size: 70px;
        margin-top: 100px;
    }
    .hero-container > p {
        font-size: 30px;
    }
}

@media screen and (max-width: 768px) {
    .mobile_title_container {
        display: flex;
        justify-content: center;
        margin-top: 180px;
    }

    .mobile_title_container > h1 {
        font-family: 'Shadows Into Light Two', cursive;
        font-weight: bold;
        font-size: 3.3rem;
        color: white;
    }

    .hero-container > h1 {
        display: none;
    }   

    .hero-container > p {
        display: none;
    }

}
