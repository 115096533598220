.summary__wrapper {
    background: linear-gradient(to right, #f5f5f5, #e8e8e8);
    padding-top: 60px;
    padding-bottom: 60px;
    padding-left: 10px;
    padding-right: 10px;
}

.summary-container {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 1200px; /* Adjusted for wider layout */
    margin: 0px auto;
    padding: 20px;
   /* background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.summary-title {
   font-family: 'Shadows Into Light Two', cursive;
    font-weight: bold;
    font-size: 3rem;
    margin-bottom: 30px;
    color: #b0843e;
    text-align: center;
}

.summary-columns {
    display: flex;
    justify-content: space-between;
}

.summary-list {
    list-style: none;
    padding: 0;
    width: 48%;
}

.summary-list li {
    margin-bottom: 15px;
    font-size: 1.1rem;
    animation: fadeIn 0.5s ease-in-out;
    position: relative;
    padding-left: 30px;
    font-family: 'Lato', sans-serif;
}

.summary-list li:before {
    content: '•';
    color: #b0843e; 
    font-size: 1.5rem;
    position: absolute;
    left: 0;
}
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@media (max-width: 768px) {
    .summary__wrapper {
        background: linear-gradient(to right, #f5f5f5, #e8e8e8);
        padding-top: 0px;
    }

    .summary-title {
        font-size: 2rem;
    }

    .summary-list li {
        font-size: 0.75rem;
    }

    .summary-list li:before {
        font-size: 1rem;
    }
}
