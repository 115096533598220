.attractions-section {
    background: linear-gradient(to right, #f5f5f5, #e8e8e8);
    padding: 30px;
  }
  
  .attractions-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 80px 30px;
    padding: 20px;
  }
  
  .attraction-card {
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
  }

  .attraction-card a {
    position: relative;
    display: block;
  }
  

  .attractions-title { 
    font-family: 'Shadows Into Light Two', cursive;
    font-weight: bold;
    font-size: 3rem;
    color: #b0843e;
    margin-left: 20px;
}
  
  .attraction-card img {
    width: 100%;
    height: 250px;
    object-fit: cover;
    transition: opacity 0.3s ease;
  }
  
  .attraction-info {
    background: white;
    padding: 15px;
    flex-grow: 1; /* Makes the info section fill the available space */
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* Adjusts content to fill the space nicely */
  }
  
  .attraction-info h3 {
    color: #333;
    margin-bottom: 10px;
    font-family: 'Lato', sans-serif;
    font-weight: bold;
  }
  
  .attraction-info p {
    color: #555;
    font-size: 1rem;
    font-family: 'Lato', sans-serif;
  }
  
  .attraction-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0,0,0,0.2);
  }

  .attraction-card a:hover::after {
    content: ''; /* Add a content property to use the pseudo-element */
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.1); /* Semi-transparent overlay */
    transition: opacity 0.3s ease;
  }

  .attraction-card a::before {
  content: '\f35d'; /* FontAwesome external link icon */
  font-family: 'Font Awesome 5 Free';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff; /* Icon color */
  font-size: 2rem; /* Adjust size as needed */
  opacity: 0; /* Icon is not visible initially */
  transition: opacity 0.3s ease-in-out;
}

.attraction-link {
  position: relative;
  display: block;
}

.external-link-icon {
  display: none; /* Hide the icon by default */
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  padding: 5px;
}

.attraction-card a:hover .external-link-icon {
  display: block; /* Only show the icon when the anchor is hovered */
}


.attraction-card a:hover::before {
  opacity: 1; /* Show the icon on hover */
}

.attraction-card a:hover img {
  opacity: 0.7; /* Dim the image to give a visual cue */
}
  
  
  @media screen and (max-width: 768px) {
    .attractions-section {
      padding: 0px;
    }
    .attractions-grid {
      grid-template-columns: repeat(2, 1fr);
      gap: 20px;
    }
    .attraction-info p {
      font-size: 1rem;
    }
  }
  
  @media screen and (max-width: 480px) {
    .attractions-section {
      padding: 0px;
    }
    .attractions-grid {
      grid-template-columns: 1fr;
      gap: 10px;
    }
  }
  