.overview-wrapper {
    padding: 120px 15%;
    background: linear-gradient(to right, #f5f5f5, #e8e8e8);
}

.overview-title {
    font-family: 'Shadows Into Light Two', cursive;
    font-weight: bold;
    font-size: 3rem;
    color: #b0843e;
}

.overview-text{
    margin-top: 50px;
    font-family: 'Lato', sans-serif;
    line-height: 45px;
    font-size: 24px;
    color: #4a4a49;
}

@media screen and (max-width: 768px) {
    .overview-wrapper {
        padding: 50px 5%;
    }

    .overview-title {
        font-size: 2.5rem;
        text-align: center;
    }

    .overview-text{
        line-height: 35px;
        font-size: 18px;
        text-align: center;
    }
  }
  