.activities-section {
    background: linear-gradient(to right, #f5f5f5, #e8e8e8);
  }
  
  .activities-container {
    padding: 70px;
  }

  .activities-title {
    font-family: 'Shadows Into Light Two', cursive;
    font-weight: bold;
    font-size: 3rem;
    color: #b0843e;
  }
  
  
  .activities-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
  }
  
  .activity-card {
    border: 1px solid #ccc;
    border-radius: 8px;
    overflow: hidden;
    background: white;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Subtle shadow */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover */
  }
  
  .activity-card img {
    width: 100%;
    height: 250px; /* Adjust height as needed */
    object-fit: cover;
    border-radius: 4px 4px 0 0; /* Rounded top corners */
  }
  
  .activity-card h3 {
    margin-top: 15px;
    font-size: 1.4rem; /* Adjusted font size */
    font-weight: bold;
    color: #333; /* Darker color for better readability */
    font-family: 'Merriweather', serif; /* Stylish serif font */
    text-align: center;
  }
  
  .activity-card p {
    margin: 10px 15px 15px; /* Adjusted padding and margin for better alignment */
    font-size: 1rem; /* Comfortable reading size */
    line-height: 1.4; /* Better line spacing */
    color: #555; /* Slightly lighter color for the description */
    font-family: 'Open Sans', sans-serif; /* Clean sans-serif font */
  }
  
  .activity-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0,0,0,0.15);
  }
  
  @media screen and (max-width: 768px) {
    .activities-grid {
      grid-template-columns: repeat(1, 1fr); /* 2 columns for smaller screens */
    }
  }
  
  @media screen and (max-width: 480px) {
    .activities-grid {
      grid-template-columns: 1fr; /* 1 column for very small screens */
    }
  }
  