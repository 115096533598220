/* LocationFull.css */
.container {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 20px;
    padding-top: 100px;
    background: linear-gradient(to right, #f5f5f5, #e8e8e8);
  }
  
.title {
  font-family: 'Shadows Into Light Two', cursive;
  font-weight: bold;
  font-size: 3rem;
  color: #b0843e;
}

  .text-section {
    flex: 3;
    text-align: left;
  }
  
  .map-section {
    flex: 2;
  }

  .gmapIframe {
    width: 100%;
    height: 100%;
    border-radius: 5px;
  }
  
  .directions {
    text-align: left;
    max-width: 900px;
    margin: auto;
    text-align: left;
    padding-right: 20px;
  }
  
 
  .directions h3 {
    color: #333;
    margin-top: 30px; /* More space above each sub-heading */
    margin-bottom: 15px; /* More space below each sub-heading */
    font-size: 1.5rem; /* Slightly larger font size for sub-headings */
    font-family: 'Lato', sans-serif;
  }
  
  .directions p {
    color: #555;
    font-size: 1rem;
    line-height: 1.6; /* Improved line spacing for readability */
    margin-bottom: 20px; /* Adds space below each paragraph */
    font-family: 'Lato', sans-serif;
    text-align: left;
  }
  

  /* Responsive Design for smaller screens */
  @media screen and (max-width: 768px) {
    .container {
      display: block;
      padding-top: 80px;
    }
  
    .map-section {
      height: 300px;
      width: 100%;
    }
  
    .text-section {
      padding-right: 0; /* Remove padding on smaller screens */
    }

    .title {
      text-align: center;
      font-size: 2.5rem;
    }

    .directions h3 {
     text-align: center;
     font-size: 1.25rem;
    }
    
    .directions p {
      text-align: center;
      font-size: 0.9rem;
      line-height: 2; /* Improved line spacing for readability */
    }
  }
  