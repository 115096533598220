* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'PT Sans', sans-serif;
  }
  
  .home,
  .services,
  .sign-up {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
  }
  
  .services {
    background-image: url('/Users/postgres/Documents/balgonie-website/public/images/processed (1).jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }
  
 
  .sign-up {
    background-image: url('/Users/postgres/Documents/balgonie-website/public/images/processed (3).jpeg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #fff;
    font-size: 100px;
  }


.image-gallery-container {
  margin: 20px;
  max-width: 800px; 
  margin: auto;
}
