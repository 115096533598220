.navbar {
  background: linear-gradient(135deg, #003B46, #07575B);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  top: 0;
  z-index: 999;
  padding: 0 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); 
}

.navbar-container {
  display: flex;
  align-items: center;
  height: 80px;
  width: 100%;
  max-width: 1700px;
  margin: 0 auto;
}

.navbar-logo {
  color: #F0F3BD;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
  font-weight: bold; 
  margin-right: auto;
}

.nav-menu {
  display: flex;
  align-items: center;
  list-style: none;
  margin-left: auto;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: #F0F3BD;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  transition: background-color 0.3s; 
}

.nav-links:hover {
  background-color: #C4DFE6;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.fa-bars, .fa-times {
  color: #fff;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
    background: linear-gradient(135deg, #003B46, #07575B);
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
    display: none;
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #F0F3BD;
    padding: 14px 20px;
    border: 1px solid #F0F3BD;
  }

  .nav-links-mobile:hover {
    background: #559ee9;
    color: #fff;
    transition: 250ms;
  }
}
